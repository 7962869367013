import "./App.css";
import Header from "./components/Header"
import Hero from "./components/Hero/Hero"
import Slider from "./components/Slider/Slider"
import Product from "./components/Products/Products"

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Slider />
      <Product />
    </div>
  );
}

export default App;
