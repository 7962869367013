import React from "react";
import css from "./Hero.module.css"   
import HeroImage from "../../assets/hero.png"
import {RiHomeSmile2Line} from "react-icons/ri"
import {BsArrowRight} from "react-icons/bs"


const Hero = () => {
    return (
        <div className={css.container}>
            

            <div className={css.h_sides}>
                <span> Skin protection cream</span>
                <div className={css.text2}>
                <span>Trendy Collection</span>
                <span>Seedily beautiful for your body</span>
            </div>
        </div>

        <div className={css.wapper}>
            <div className={css.blueCircle}>
                <img src={HeroImage} alt="" width={600} />
                <div className={css.cart2}>
                    <RiHomeSmile2Line/>

                    <div className={css.signup}>
                        <span>Best signup Offer</span>
                        <div>
                            <BsArrowRight />
                        </div>

                    </div>
                </div>
            </div>
        </div>



        <div className={css.h_sides}>
            <div className={css.traffic}>
                <span>1.5 m</span>
                <span>Monthly Traffic</span>
            </div>

            <div className={css.customers}>
                <span>100k</span>
                <span>Happy Customers</span>
            </div>



        </div>




    </div>
    )
}

export default Hero
